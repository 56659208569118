<template>
  <Dialog
    :dialogActivate="active"
    :functionButtonCancel="functionButtonCancel"
    :functionButtonAction="addDocument"
    title="Adicionar Documento"
    labelButtonCancel="Cancelar"
    labelButtonAction="Adicionar"
    :disabledButtonAction="invalidForm"
  >
    <div class="mt-4">
      <h3>Informações do Documento</h3>
      <v-row v-if="documentTypes">
        <v-col cols="12">
          <v-select
            v-model.lazy="document.typeDocument"
            :items="documentTypesSelect"
            item-value="value"
            item-text="label"
            outlined
            :color="'#3CA897'"
            :label="'Tipo de documento'"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            outlined
            :color="'#3CA897'"
            :label="'Número do documento'"
            :name="'Número do documento'"
            v-model.lazy="document.number"
            :error-messages="inputValidator(document.number, 'number')[1]"
            :error="inputValidator(document.number, 'number')[0]"
            @blur="inputValidator(document.number, 'number')"
            @input="inputValidator(document.number, 'number')"
            required
            type="text"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-menu
            v-model="expiresAtMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-model.lazy="computedExpiresAt"
                :error-messages="
                  inputValidator(document.expiresAt, 'expiresAt')[1]
                "
                :error="inputValidator(document.expiresAt, 'expiresAt')[0]"
                @blur="inputValidator(document.expiresAt, 'expiresAt')"
                @input="inputValidator(document.expiresAt, 'expiresAt')"
                :label="'Validade'"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              locale="pt-BR"
              color="orange darken-2"
              header-color="orange darken-2"
              v-model.lazy="document.expiresAt"
              @input="expiresAtMenu = false"
              @change="expiresAtChange"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <UploadFile @uploadFile="updateFile" />
        </v-col>
      </v-row>
    </div>
  </Dialog>
</template>

<script>
  import { isEmpty } from "lodash";
  import { mapActions, mapGetters } from "vuex";

  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import Dialog from "@/modules/core/views/components/Dialog";
  import UploadFile from "@/modules/core/views/components/UploadFile";

  export default {
    name: "AddDocument",

    props: {
      active: {
        type: Boolean,
        required: true,
        default: () => false
      },
      documents: {
        type: Array,
        required: true,
        default: () => []
      },
      functionButtonCancel: {
        type: Function,
        required: true,
        default: () => new Function()
      },
      functionButtonAction: {
        type: Function,
        required: true,
        default: () => new Function()
      }
    },

    components: {
      Dialog,
      UploadFile
    },

    mixins: [MasksMixin],

    async created() {
      const { data } = await this.getRecyclerDocumentType(
        this.$route.params.id
      );
      this.documentTypes = data;
      this.documentTypesSelect = data.map(
        el => `${el.initials} (${el.description})`
      );
    },

    data: () => ({
      documentTypes: null,
      documentTypesSelect: [],
      expiresAtMenu: false,
      document: {
        typeDocument: {},
        number: undefined,
        file: undefined,
        expiresAt: undefined
      }
    }),

    watch: {
      expiresAtMenu(val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
      }
    },

    computed: {
      computedExpiresAt() {
        if (this.document.expiresAt) {
          return this.document.expiresAt
            .split("-")
            .reverse()
            .join("/");
        }
        return null;
      },

      invalidForm() {
        return (
          isEmpty(this.document.typeDocument) ||
          isEmpty(this.document.number) ||
          isEmpty(this.document.expiresAt) ||
          isEmpty(this.document.file)
        );
      }
    },

    methods: {
      ...mapActions("recycler", ["getRecyclerDocumentType"]),
      ...mapActions(["toggleSnackbar"]),

      expiresAtChange(val) {
        if (val) {
          this.document.expiresAt = val;
        }
      },

      updateFile(file) {
        this.document.file = { ...file };
      },

      async addDocument() {
        let type = this.documentTypes.find(
          el =>
            `${el.initials} (${el.description})` === this.document.typeDocument
        );
        this.document.typeDocument = { id: type.id, doc: type };
        this.documents.push(JSON.parse(JSON.stringify(this.document)));
        this.document = {};
        this.functionButtonCancel();
      },

      inputValidator(value, inputName) {
        if (value === undefined) {
          return [false, ""];
        }
        if (!value) {
          return [true, this.$t.root.errorsForm.requiredInput];
        }
        return [false, ""];
      }
    }
  };
</script>
