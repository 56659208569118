<template>
  <card>
    <template v-slot:table>
      <v-form @submit.prevent="submitForm">
        <div class="form-register">
          <p>{{ "Adicionar Documento" }}</p>
          <v-row>
            <v-col cols="12">
              <div class="container-attachment">
                <p
                  @click="() => (addDocumentDialogActive = true)"
                  class="attachment-title"
                >
                  <v-icon class="attachment-icon"
                    >mdi-plus-circle-outline</v-icon
                  >
                  Adicionar Documento
                </p>
              </div>
            </v-col>
          </v-row>

          <div class="container-list-attachments">
            <v-row
              v-for="(document, i) in documents"
              :key="i"
              class="container-list-attachment"
            >
              <v-col cols="11">
                <p class="address-list-attachment">
                  {{
                    `${document.typeDocument.doc.initials} - ${document.typeDocument.doc.description})`
                  }}
                </p>
                <p class="zipcode-list-attachment">
                  {{ `Válido até o dia ${parseDate(document.expiresAt)}` }}
                </p>
              </v-col>
              <v-col cols="1">
                <v-icon
                  @click="delDocument(document)"
                  class="delete-list-attachment-icon"
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>
          </div>
        </div>

        <v-divider></v-divider>

        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            @click="$router.push({ name: 'users' })"
            color="#ffffff"
            class="cancel-button black-3--text"
            >{{ $t.root.actionButtonLabels.exit }}</v-btn
          >
          <v-btn
            type="submit"
            :disabled="documents.length <= 0"
            color="secondary-color-1"
            class="next-step-button white--text"
            >{{ $t.root.actionButtonLabels.send }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <AddDocument
        v-if="addDocumentDialogActive"
        :active="addDocumentDialogActive"
        :documents="documents"
        :functionButtonCancel="() => (addDocumentDialogActive = false)"
        :functionButtonAction="() => {}"
      />
    </template>
  </card>
</template>

<script>
  import _ from "lodash";
  import { mapActions } from "vuex";
  import { ptBR } from "date-fns/locale";
  import { format, addDays } from "date-fns";

  import AddDocument from "@/modules/recycler/views/components/AddDocument";
  import Card from "@/modules/core/views/templates/CardLayout";

  export default {
    name: "RegisterDocument",

    components: {
      Card,
      AddDocument
    },

    data: () => ({
      addDocumentDialogActive: false,
      documents: []
    }),

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("recycler", ["addDocumentRecycler"]),

      parseDate(date) {
        return format(addDays(new Date(date), 1), "dd MMM yyyy", {
          locale: ptBR
        });
      },

      delDocument(document) {
        this.documents.splice(this.documents.indexOf(document), 1);
      },

      async submitForm() {
        this.documents.forEach(el => {
          delete el.typeDocument.doc;
        });

        const payload = {
          payload: { documents: this.documents },
          id: this.$route.params.id
        };

        try {
          const { data } = await this.addDocumentRecycler(payload);
          this.$router.push({ name: "recicladores" });
          this.toggleSnackbar({
            text: "Documentos adicionados com sucesso!",
            type: "success"
          });
        } catch (err) {
          const message = _.get(err, "response.data.message");
          this.toggleSnackbar({
            text: message,
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/forms";
  @import "@/assets/stylesheets/components";

  @include form-register();
  @include container-attachment();
</style>
